import React from "react";
import { textOrHtml } from "./BrandForm";
import { LabelStyledLeft } from ".";

export function SalesforceWebToLeadForm({ intro, validationInfo, introSubHeaderClassNames, introSubHeader, ttdImagePixel }) {
    const inputClassNames = "text-sm flex-grow transition-all outline-none block input-border active:ring w-full";

    return (
        <>
            <div className="my-8 mx-auto px-2 sm:w-1/2 xl:w-1/3">
                <h1 className={`mb-6 text-3xl font-bold ${intro.textClassName ? intro.textClassName : ''}`}>{textOrHtml(intro)}</h1>
                {introSubHeader ? (
                    <p className={`text-lg ${introSubHeaderClassNames ? introSubHeaderClassNames : ''}`}><span dangerouslySetInnerHTML={{ __html: introSubHeader }} /></p>
                ) : <></>}

                <p className="text-lg">{validationInfo}</p>
            </div>

            <div>
                <form action={process.env.REACT_APP_SALESFORCE_W2L_URL} className="mx-auto flex justify-center w-full" method="POST">
                    <input type="hidden" name="oid" value={process.env.REACT_APP_SALESFORCE_OID} />
                    <input type="hidden" name="retURL" value={`${window.location}?success=true`} />

                    {/* Uncomment the below lines, to get debug values when the form is submitted
                        <input type="hidden" name="debug" value={1} />
                        <input type="hidden" name="debugEmail" value="it@roiltd.co.uk" /> */}                   

                    <div className="grid gap-5 mb-5 lg:grid-cols-1 gap-y-8 xl:grid-cols-6">
                        <div className="col-span-2">
                            <LabelStyledLeft>
                                Title <RequiredInput />
                                <select className={inputClassNames}
                                    id="salutation" name="salutation"
                                    required
                                >
                                    <option value="">--None--</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof.">Prof.</option>
                                </select>
                            </LabelStyledLeft>
                        </div>

                        <div className="col-span-2">
                            <LabelStyledLeft>
                                First Name <RequiredInput />
                                <input required className={inputClassNames} id="first_name" maxLength="40" name="first_name" size="20" type="text" />
                            </LabelStyledLeft>
                        </div>

                        <div className="col-span-2">
                            <LabelStyledLeft>
                                Last Name <RequiredInput />
                                <input required className={inputClassNames} id="last_name" maxLength="80" name="last_name" size="20" type="text" />
                            </LabelStyledLeft>
                        </div>


                        <div className="col-span-3">
                            <LabelStyledLeft>
                                Email <RequiredInput />
                                <input required className={inputClassNames} id="email" maxLength="80" name="email" size="20" type="email" />
                            </LabelStyledLeft>
                        </div>

                        <div className="col-span-3">
                            <LabelStyledLeft>
                                Phone <RequiredInput />
                                <input required className={inputClassNames} id="phone" maxLength="11" name="phone" size="20" type="tel" />
                            </LabelStyledLeft>
                        </div>

                        <div className="col-span-3">
                            <LabelStyledLeft>
                                Company <RequiredInput />
                                <input required className={inputClassNames} id="company" maxLength="40" name="company" size="20" type="text" />
                            </LabelStyledLeft>
                        </div>

                        <div className="col-span-3">
                            <LabelStyledLeft>Fleet Size <RequiredInput />
                                <select required className={inputClassNames} id="00N8d00000UWNCw" name="00N8d00000UWNCw">
                                     <option value="25-74">25-74</option>
                                    <option value="75+">75+</option>
                                </select>
                            </LabelStyledLeft>
                        </div>


                        <input type="hidden" name="lead_source" value="Media Campaign Nov23" />

                        <div className="col-span-6">
                            <input className="px-4 py-3 btn gap-4 group btn-secondary bg-blue-hyundai-dark text-white hover:bg-blue-hyundai-light px-4 py-3 justify-center
                             my-12 mx-auto disabled:opacity-50 disabled:bg-white disabled:cursor-default flex items-center cursor-pointer" type="submit" name="submit" />

                            {ttdImagePixel ? (
                                <img height="1" width="1" style={{ borderStyle: "none" }} alt="" src={ttdImagePixel} />
                            ) : (<></>)}
                        </div>
                    </div>
                </form >
            </div >
        </>
    )
}

function RequiredInput() {
    return (
        <span className="text-red-600">*</span>
    )
}