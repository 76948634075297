import React from "react";

export function ImageAwardTile({ details }) {
    return (
        <div className="relative" style={{
            background: `url(${details.imagePath}) center no-repeat`,
            backgroundSize: 'cover'
        }}>
            {details.awardImages ? (
                <>
                    {details.awardImages.map(x => (
                        <img alt="car and award" className={x.classNames} src={x.imagePath} />
                    ))}
                </>
            ) : (<></>)}

        </div>
    )
}