import React from 'react';
import { default as ChevronSVG } from './Chevron';
import { default as TickSVG } from './Tick';

const icons = {
    chevron: ChevronSVG,
    tick: TickSVG
};

export default function IconComponent({ className, colour, height, icon, onClick, width }) {
    const Icon = icons[icon];
    return (
        <div className={`${className} ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
            <Icon colour={colour} height={height} width={width} />
        </div>
    );
};