import React from 'react';

export default function Tick({ width = 19, height = 19, colour = '' }) {
    return (
        <svg className={'stroke-current ' + colour} viewBox="0 0 12 16" height={height} width={width}>
            <g transform="translate(1.407 1.406)">
                <path
                    d="M7,24.013l2.273,2.8a1.265,1.265,0,0,0,1.954-.012l7.5-9.3"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    transform="translate(-7 -17.5)"
                />
            </g>
        </svg>
    );
}
