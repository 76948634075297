import React from 'react';
import loading from '../../styles/images/Loading.gif';
import { gtag } from '../../services/hyundaiTracking';
import { cookieGetCookiePreferences } from '../../services/cookies';

export function Saving() {
    return (
        <div className="w-full p-2 text-center align-center">
            <p>Please wait</p>
            <img className="m-auto m-5 h-9" src={loading} alt="Please wait" />
            <p>Your details are being submitted.</p>
        </div>
    );
}

export function Saved({ onSavedAnalyticsArgs, trackingImgSrc }) {
    const hasCookieConsent = cookieGetCookiePreferences() === 'true';


    if (onSavedAnalyticsArgs && hasCookieConsent) {
        gtag("event", "conversion", onSavedAnalyticsArgs);
    }
    return (
        <>
            <div className="w-full p-2 text-center">
                <h2>Thanks!</h2>
                <p>Your details have been submitted and someone will be in contact with you shortly.</p>
            </div>
            {trackingImgSrc && hasCookieConsent ? (

                <img src={trackingImgSrc} width="1" height="1" alt="" />

            ) : (<></>)
            }
        </>
    );
}

export function Error() {
    return (
        <div className="w-full p-2 text-center">
            <h2>Sorry!</h2>
            <p>An error has occured. We're working hard to fix this.</p>
            <p>Please try again later.</p>
        </div>
    );
}

export function NotAvailable() {
    return (
        <div className="w-full p-2 text-center">
            <h2>Sorry!</h2>
            <p>The page you are looking for is no longer available.</p>
        </div>
    );
}
