import { Citroën, template, Peugeot/*, HyundaiBusiness, Hyundai*/ } from '../json/sets';

const sets = {
    template: template,
    Peugeot: Peugeot,
    Citroen: Citroën,
    // HyundaiBusiness: HyundaiBusiness,
    // Hyundai: Hyundai
};

export default sets;
