import { useJsonData } from '../../hooks';

export const NavItem = ({ styles, active, name, href, target = "_self" }) => {
    const { brand } = useJsonData();

    return (
        <div className={`h-full border-b-2 border-black align-middle flex flex-row items-center hover:border-${brand} cursor-pointer`}>
            <a href={href} target={target} className={`px-4 text-${brand}`} active={active} styles={styles}>
                {name}
            </a>
        </div>
    );
};

export default NavItem;