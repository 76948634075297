import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthenticatedLayout } from './components';
import { Home } from './pages';
import { CookiePolicy } from './pages/cookies/CookiePolicy';

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route key="cookie-policy" exact path="/cookie-policy" component={CookiePolicy} />
                <Route exact path="/:d?">
                    <AuthenticatedLayout>
                        <Home />
                    </AuthenticatedLayout>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}