import React, { useEffect, useState } from 'react';
import {
    Saving,
    Saved,
    Error,
    NotAvailable
} from '../../components';
import { useJsonData } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { compareDesc } from 'date-fns';
import { ImageAwardTile } from '../../components/awardTiles/ImageAwardTile';
import { TextAwardTile } from '../../components/awardTiles/TextAwardTile';
import { BrandForm, states } from '../../components/form/BrandForm';
import { replaceLinksWithComponent } from '../../services/links';
import { SalesforceWebToLeadForm } from '../../components/form/SalesforceWebToLeadForm';
import { initializeGoogleAnalytics, initializeGoogleAnalyticsEvent, initializeGoogleTagManagerBody, initializeGoogleTagManagerHead, initializeLinkedInTracking, initializePixelTAMTracking, initializePixelTTDTracking, initializeTeadsCdns } from '../../services/hyundaiTracking';
import { CookieBanner } from '../../components/cookieBanner/CookieBanner';
import { cookieGetCookiePreferences } from '../../services/cookies';
//import { useParams } from 'react-router-dom';

export default function Home() {
    const [status, setStatus] = useState(states.default);
    const [selectedVehicle, setSelectedVehicle] = useState('Any');
    const [showCookieBanner, setBannerVisibility] = useState(false);

    const data = useJsonData();
    const {
        brand,
        showFooter,
        showFooterLogo,
        footerNavItems,
        subHeader,
        subHeaderTitle,
        subHeaderText,
        vehicleSelector,
        vehicleSelectorItems,
        showFullWidthFooter,
        footerLeftColumnLogoImgName,
        footerLeftColumnLogoLink,
        footerRightColumnText,
        footerRightColumnSubText,
        endDate,
        subHeaderTitleClassNames,
        subHeaderTitleMarginBottom,
        subHeaderTextClassNames,
        intro,
        questions,
        hasSellingPoints,
        sellingPoints,
        showAwardsSection,
        awardsSection,
        showSecondaryForm,
        secondaryForm,
        footerLeftColumnSocialMediaLinks,
        footerLeftColumnText,
        useCustomSalesforceFormSubmit,
        customTracking,
        hasCookieBanner
    } = data;

    // below is for use with the cookiePolicy page
    // const { d } = useParams();
    // const qsId = d;

    const history = useHistory();

    //if it has an endDate, stop showing the site on this date - redirect user to page not found message
    useEffect(() => {
        const endDateFormatted = new Date(endDate);
        const todayFormatted = new Date();
        const compare = compareDesc(todayFormatted, endDateFormatted);

        if (compare === 0) history.push('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //custom tracking/analytics
    const userHasGivenConsent = cookieGetCookiePreferences();
    useEffect(() => {
        if (userHasGivenConsent === 'true') {
            if (customTracking && customTracking.linkedInPartnerId && customTracking.linkedInNoScriptSrc) {
                initializeLinkedInTracking(customTracking.linkedInPartnerId, customTracking.linkedInNoScriptSrc);
            }
            if (customTracking && customTracking.teadsJsCdnLink && customTracking.jsUpLoaderCdnLink) {
                initializeTeadsCdns(customTracking.teadsJsCdnLink, customTracking.jsUpLoaderCdnLink);
            }
            if (customTracking && customTracking.universalPixelTTDFirstArg && customTracking.universalPixelTTDSecondArg) {
                initializePixelTTDTracking(customTracking.universalPixelTTDFirstArg, customTracking.universalPixelTTDSecondArg);
            }
            if (customTracking && customTracking.universalPixelTAMPixelId) {
                initializePixelTAMTracking(customTracking.universalPixelTAMPixelId);
            }
            if (customTracking && customTracking.googleAnalyticsId) {
                initializeGoogleAnalytics(customTracking.googleAnalyticsId);
            }
            if (customTracking && customTracking.landingPageGoogleEventArgs) {
                initializeGoogleAnalyticsEvent(customTracking.landingPageGoogleEventArgs);
            }
            if (customTracking && customTracking.hasTagManager && customTracking.tagManagerIframeSrc
                && customTracking.tagManagerId) {
                initializeGoogleTagManagerHead(customTracking.tagManagerId);
                initializeGoogleTagManagerBody(customTracking.tagManagerIframeSrc);
            }
        }
    }, [customTracking, userHasGivenConsent, showCookieBanner])

    if (!brand) return <NotAvailable />;

    const urlParams = new URLSearchParams(window.location.search);
    let qsStatus = urlParams.get('success');
    
    //below is for recognizing querystring when the url contains the '#secondForm hash
    const hash = window.location.hash;
    if (hash?.includes("?success=true")) {
        qsStatus = 'true';
    }

    if (status === states.saving) return <Saving />;

    if (status === states.saved || qsStatus === 'true') return <Saved
        onSavedAnalyticsArgs={customTracking?.formSavedGoogleConversionEventArgs}
        trackingImgSrc={customTracking?.formSavedTrackingImgSrc
        } />;

    if (status === states.error) return <Error />;
    const lowerBrand = brand?.toLowerCase();

    const selectedVehicleBgColour = `${lowerBrand ? 'bg-blue-' + lowerBrand + '-light' : 'bg-blue-light'}`;
    const defaultVehicleBgColour = `${lowerBrand ? 'bg-blue-' + lowerBrand + '-dark' : 'bg-blue-standard'}`;

    return (
        <div style={{overflowX: 'hidden'}}>
            {subHeader ? (
                <div className="m-auto px-12 pt-12 sm:1-2/3 lg:w-1/2">
                    <div className="content-center">
                        <h2 className={`${subHeaderTitleMarginBottom ? subHeaderTitleMarginBottom : 'mb-8'} text-2xl ${subHeaderTitleClassNames ? subHeaderTitleClassNames : ''}`}>{subHeaderTitle}</h2>
                        <p className={`text-lg ${lowerBrand ? 'text-' + lowerBrand + '-grey-text' : 'text-grey'} ${subHeaderTextClassNames ? subHeaderTextClassNames : ''}`}>
                            {subHeaderText}
                        </p>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {hasSellingPoints && sellingPoints.content?.length > 0 ? (
                <div className={`my-8 ${sellingPoints.classNames ? sellingPoints.classNames : ''}`}>
                    <div className="justify-center mx-4 lg:mx-8 xl:mx-12 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
                        {sellingPoints.content.map(x => (
                            <div className="m-8 text-center">
                                <div className="h-16 t-0 b-0 m-auto">
                                    <h1 className="text-2xl mx-2 text-blue-dark font-bold">{x.headerText}</h1>
                                </div>
                                <img alt="Selling point" className="w-60 max-w-fit text-center m-auto" src={x.imagePath} />
                                <p className="mt-4">{x.paragraphText} </p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (<></>)}

            {
                vehicleSelector ? (
                    <div className={`mt-16 pb-20 ${lowerBrand ? 'bg-' + lowerBrand + '-bg-grey' : 'bg-grey'}`}>
                        <div className="m-auto px-12 pt-10 sm:1-2/3 md:w-1/2">
                            <div className="text-center mb-6">
                                <h2 className="text-3xl font-bold">Select Model</h2>
                            </div>
                            <div className="grid lg:grid-cols-1 gap-y-8 xl:grid-cols-3 gap-x-32">
                                {vehicleSelectorItems &&
                                    vehicleSelectorItems.map((x) => (
                                        <div key={x.id}>
                                            <img
                                                alt={`Hyundai ${x.modelName}`}
                                                src={`/images/HyundaiVehicleImages/${x.imageName}`}
                                            />
                                            <div className="text-center mb-4">
                                                <h1 className="uppercase text-3xl font-bold">{x.modelName}</h1>
                                                <h2 className="text-2xl">{x.fuelType}</h2>
                                            </div>

                                            <ul className="text-sm font-semibold">
                                                {x.evRange ? (
                                                    <li
                                                        className={`${lowerBrand ? 'bg-' + lowerBrand + '-list' : 'bg-gray'
                                                            } text-center p-2 mb-1`}
                                                    >
                                                        EV Range: {x.evRange}
                                                    </li>
                                                ) : x.p11d ? (
                                                    <li
                                                        className={`${lowerBrand ? 'bg-' + lowerBrand + '-list' : 'bg-gray'
                                                            } text-center p-2 mb-1`}
                                                    >
                                                        P11d: {x.p11d} **
                                                    </li>
                                                ) : (
                                                    <></>
                                                )}

                                                <li
                                                    className={`${lowerBrand ? 'bg-' + lowerBrand + '-list' : 'bg-gray'
                                                        } text-center p-2 mb-1`}
                                                >
                                                    BIK: {x.bik}%
                                                </li>


                                                {x.rapidChargeMins ? (
                                                    <li
                                                        className={`${lowerBrand ? 'bg-' + lowerBrand + '-list' : 'bg-gray'
                                                            } text-center p-2 mb-1`}
                                                    >
                                                        Rapid Charge: {x.rapidChargeMins} minutes*
                                                    </li>
                                                ) : x.co2 ? (
                                                    <li
                                                        className={`${lowerBrand ? 'bg-' + lowerBrand + '-list' : 'bg-gray'
                                                            } text-center p-2 mb-1`}
                                                    >
                                                        CO2: {x.co2}g/km
                                                    </li>
                                                ) : <></>}

                                            </ul>
                                            <div className="mt-6">
                                                <button
                                                    className={`w-full ${selectedVehicle && selectedVehicle.id === x.id
                                                        ? selectedVehicleBgColour
                                                        : defaultVehicleBgColour
                                                        }  text-white
                                                text-center p-4 rounded-lg `}
                                                    onClick={() => setSelectedVehicle(x)}
                                                >
                                                    Test drive this car
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }

            {useCustomSalesforceFormSubmit ? (
                <SalesforceWebToLeadForm intro={secondaryForm}
                    introSubHeader={secondaryForm.introSubHeader} intoSubHeaderClassNames="text-center" ttdImagePixel={customTracking?.ttdPixelImageSrc} />
            ) : (<BrandForm brand={brand} status={status} setStatus={setStatus}
                selectedVehicle={selectedVehicle} questions={questions}
                intro={intro} introSubHeader={intro.subHeader} intoSubHeaderClassNames="text-center"

            />)}

            {
                showAwardsSection ? (
                    <div className="grid grid-cols-2 bg-blue-sky">
                        {awardsSection.content.map(x => (
                            <> {x.isImageTile ? (<ImageAwardTile brand={brand} details={x} />) : (<TextAwardTile brand={brand} details={x} />)}</>
                        ))}
                    </div>
                ) : (<></>)
            }

            <div id="secondForm">
                {showSecondaryForm && !useCustomSalesforceFormSubmit ? (
                    <BrandForm brand={brand} status={status} setStatus={setStatus}
                        selectedVehicle={selectedVehicle} questions={questions}
                        intro={secondaryForm} introSubHeader={secondaryForm.introSubHeader} intoSubHeaderClassNames="text-center" />

                ) : showSecondaryForm && useCustomSalesforceFormSubmit ? (<SalesforceWebToLeadForm
                    intro={secondaryForm} introSubHeader={secondaryForm.introSubHeader} intoSubHeaderClassNames="text-center"
                    ttdImagePixel={customTracking?.ttdPixelImageSrc} />)
                    : <></>
                }
            </div>

            {showFooter && (
                <div className={`w-full bg-${brand} p-8`}>
                    {showFooterLogo && (
                        <div className="flex w-full justify-center">
                            {brand && <img alt="logo" style={{ height: '60px' }} src={`/images/${brand}Logo.png`} />}
                        </div>
                    )}
                    <div className="flex w-full justify-center pt-8">
                        {(footerNavItems || []).map((n, i) => (
                            <a
                                key={i}
                                className={`text-${brand}-footer px-4 my-4 border-r-2 border-${brand}-footer last:border-0`}
                                target="_none"
                                href={n.hyperlink}
                            >
                                {n.text}
                            </a>
                        ))}
                        {/* {customTracking?.includeRoiPrivacyPolicy ? (
                            <a
                                className={`text-${brand}-footer px-4 my-4 border-r-2 border-${brand}-footer last:border-0`}
                                href={'/cookie-policy?id=' + qsId}
                            >Cookie Policy</a>
                        ) : <></>} */}
                    </div>
                </div>
            )}

            {showFullWidthFooter ? (
                <div className="flex w-full bg-black text-white">
                    <div className="justify-left">
                        <a target="_blank" rel="noreferrer" href={footerLeftColumnLogoLink}>
                            <img
                                alt="manufacturer logo"
                                className="pt-8 mb-6 xs:ml-2 sm:ml-12"
                                src={`/images/${footerLeftColumnLogoImgName}`}
                            />
                        </a>

                        {footerLeftColumnSocialMediaLinks ? (
                            <div className="w-2/12 mx-8 grid grid-cols-3 gap-2">
                                {footerLeftColumnSocialMediaLinks?.map(x => (
                                    <div>
                                        <a href={x.link} rel='noreferrer' target="_blank"><img alt="social media icon" className="w-14" src={x.imageUrl} /></a>
                                    </div>
                                ))}
                            </div>
                        ) : (<></>)}

                        {footerLeftColumnText ? (
                            <div className="m-6">
                                {footerLeftColumnText ? footerLeftColumnText.map(x => (
                                    <p className={`${x.className ? x.className : ''}`}>
                                        {replaceLinksWithComponent(x, 'cursor-pointer border-b-1 border-gray-dim')}
                                    </p>
                                )) :
                                    <></>}
                                {/* {customTracking?.includeRoiPrivacyPolicy ? (
                                    <a
                                        className='cursor-pointer border-b-1 border-gray-dim text-gray-dim text-xs mt-4 mb-6'
                                        href={'/cookie-policy?id=' + qsId}
                                    >Cookie Policy</a>
                                ) : <></>} */}
                            </div>
                        ) : (<></>)}



                    </div>
                    <div className="flex-grow"></div>
                    <div className="justify-right text-xs pt-8 mb-6 xs:mr-2 sm:mr-12">
                        <p>{footerRightColumnText}</p>
                        <p>{footerRightColumnSubText}</p>
                    </div>
                </div>
            ) : (
                <></>
            )
            }

            {customTracking && customTracking?.landingPageTrackingImgSrc ? (
                <>
                    <noscript>
                        <img src={customTracking?.landingPageTrackingImgSrc} width="1" height="1" alt="" />
                    </noscript>
                </>
            ) : (<></>)}

            {hasCookieBanner ? (<CookieBanner showCookieBanner={showCookieBanner} setBannerVisibility={setBannerVisibility} />) : <></>}

        </div>
    );
}
