import React from "react";
import { Button } from "../../components";
import { COOKIE_ACCEPT_ALL_COOKIES, cookieClear } from "../../services/cookies";
import { useHistory } from "react-router-dom";

export function CookiePolicy() {
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const returnId = urlParams.get('id');

    const clearCookieSettings = () => {
        cookieClear(COOKIE_ACCEPT_ALL_COOKIES);
        history.push(`/${returnId}`);
    }

    return (
        <div className="m-auto pt-12 sm:1-2/3 lg:w-1/2 py-8">
            <div className="my-4">
                <Button size="submission" onClick={() => { window.history.back() }}>Go Back</Button>
            </div>

            <h1 className="font-black">COOKIE POLICY</h1>
            <p className="mb-4">
                The technologies that we use include JavaScript tags, pixels, local storage, and web beacons, though for simplicity in this Policy we use the generic term 'cookies' to mean all and any of them
            </p>

            <p className="mb-4">This Policy concerns the roiltd.co.uk domain, and associated subdomains. The websites which this Policy concerns are collectively referred to here as the 'Website'</p>

            <p className="mb-4">We use cookies for a range of purposes. They help us to present the Website to you, and analyse how you arrive at and use the Website</p>

            <p className="mb-4">Cookies provide us with information about how the Website is used so we can keep it as up to date, relevant and error free as possible.
                Further information about the types of cookies in use on the Website is set out below</p>

            <h2 className="font-black">The types and categories of cookies we use</h2>
            <p className="mb-4">
                Cookies enable a small amount of data to be stored on your device when you access websites and mobile applications on the internet. Cookies can be either:</p>
            <ul className="list-disc px-4">
                <li>Session cookies - these expire when you close your browser and do not remain on your device; or</li>
                <li>Persistent cookies - these are stored in the longer-term on your device. They are normally used to make sure the site remembers your preferences</li>
            </ul>
            <p>The cookies that we use on the Website can be categorised as follows:</p>
            <ul className="list-disc px-4">
                <li>'Essential' cookies are used to support site navigation, search, login and other features;</li>
                <li>'Analytical' cookies support performance analysis</li>
            </ul>


            <h2 className="font-black mt-4"> Your choices in relation to cookies</h2>
            <p className="mb-4">When you first visit the Website, you will be asked to confirm whether you agree to the Analytical and cookies being placed on your device. At the same time, a small number of strictly necessary and functional cookies will be placed on your device automatically in order to allow the Website to function</p>


            <p className="mb-4">Please note that if you choose not to accept cookies, or if you configure your browser settings to reject all cookies (including essential cookies),
                certain functions and services on the Website may not be available to you and this will also affect our ability to update the Website to cater for your
                preferences and improve the Website’s performance</p>

            <p className="mb-4">The types of cookies we currently use on the Website are set out in more detail below:</p>

            <h2 className="font-black">Essential cookies</h2>
            <p className="mb-4">Essential cookies are necessary to enable you to move around the Website and use its features and/or services.
                These cookies allow the Website to provide essential services to you.</p>

            <h2>The Website uses strictly necessary cookies to:</h2>
            <ul className="list-disc px-4">
                {/* <li>Identify you as being logged in to the Website </li> */}
                <li> Make sure you connect to the right service on the Website when we make any changes to the way the Website works</li>
                <li> Identify you as having consented to use of cookies on the Website</li>
            </ul>

            <h2 className="font-black mt-4">Analytical cookies</h2>
            <p className="mb-4">Analytical cookies allow us to update the Website to cater for user preferences and improve performance.
                They collect information about how the Website is used, e.g. which pages users visit most often and what things they click on</p>

            <p className="mb-4">The Website uses performance cookies to:</p>

            <ul className="list-disc px-4">
                <li>Provide statistics on how the Website is used (including selections that you have made, files you have clicked on, and pages you have visited)</li>
                <li>Enable us to track and improve the Website by analysing visitor behaviour and results</li>
            </ul>

            <div className="mt-6">
                <Button size="submission" onClick={clearCookieSettings}>Change Cookie Settings</Button>
            </div>
        </div>
    )
}