import React, { useEffect } from "react";
import { cookieGetCookiePreferences, cookieSetCookiePreferences } from "../../services/cookies";

export function CookieBanner({ showCookieBanner, setBannerVisibility }) {

    const cookieResult = cookieGetCookiePreferences();

    useEffect(() => {
        const bannerShouldBeVisible = cookieResult ? false : true;
        setBannerVisibility(bannerShouldBeVisible);
    }, [cookieResult, setBannerVisibility]);

    const handleCookieClick = (hasAcceptedAllCookies) => {
        setBannerVisibility(false);
        cookieSetCookiePreferences(hasAcceptedAllCookies);
    }

    return showCookieBanner && (
        <>
            <div className="fixed inset-0 w-full h-full bg-gray-600 bg-opacity-60">
                <div className="p-6 items-baseline bg-gray-600 text-white flex flex-wrap justify-between left-0 fixed w-full bottom-0" style={{ background: '#432872' }}>
                    <div className="mb-2 mr-4" style={{ flex: '1 0 300px' }}>
                        <p>We use cookies to ensure that we give you the best experience on our website.
                            They also allow us to analyse user behaviour in order to continually improve the user experience
                        </p>
                    </div>
                    <div className='md:flex self-center'>
                        <button
                            className="border-2 border-white py-1 px-2 bg-white text-black mr-2 w-44 mb-2 sm:mb-0"
                            aria-label="Accept Cookies"
                            onClick={() => handleCookieClick(true)}
                        >
                            Allow All
                        </button>
                        <button className="border-2 border-white py-1 px-2 w-44"
                            aria-label="Decline Cookies"
                            onClick={() => handleCookieClick(false)}
                        >
                            Refuse Non-Essential
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}