import cookie from 'react-cookies';

export const COOKIE_ACCEPT_ALL_COOKIES = 'COOKIE_ACCEPT_ALL_COOKIES';

export const cookieGetCookiePreferences = () =>
    cookie.load(COOKIE_ACCEPT_ALL_COOKIES);

export const cookieSetCookiePreferences = (hasAcceptedAllCookies) => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    cookie.save(COOKIE_ACCEPT_ALL_COOKIES, hasAcceptedAllCookies, {
        path: '/',
        expires: oneYearFromNow
    });
};

export const cookieClear = (cookieName = null) => {
    const cookies = cookie.loadAll();
    if (cookieName != null) {
        cookie.remove(cookieName, { path: '/' });
    } else {
        Object.keys(cookies).forEach((c) => {
            cookie.remove(c, { path: '/' });
        });
    }
};