export function initializeLinkedInTracking(partnerId, linkedInNoScriptSrc) {
    if (partnerId) {
        var _linkedin_partner_id = partnerId;
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    }

    if (linkedInNoScriptSrc) {
        addNoScript(`<img height="1" width="1" style="display:none;" alt=""src=${linkedInNoScriptSrc} />`)
    }

    let scriptInner = `(function (l) {
    if (!l) {
      window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
      window.lintrk.q = []
    }
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);`;

    addScriptToHead(scriptInner);
}

export function initializeTeadsCdns(teadsCdnLink, upLoaderCdnLink) {
    addScriptToHead(null, teadsCdnLink);
    addScriptToHead(null, upLoaderCdnLink);
}

export function initializePixelTTDTracking(firstArg, secondArg) {
    if (typeof window.TTDUniversalPixelApi === 'function') {
        var universalPixelApi = new window.TTDUniversalPixelApi();
        universalPixelApi.init(firstArg, [secondArg], "https://insight.adsrvr.org/track/up");
    }
}

export function initializePixelTAMTracking(pixelId) {
    window.teads_e = window.teads_e || [];
    window.teads_buyer_pixel_id = pixelId;
}

export function initializeGoogleAnalytics(analyticsId) {
    const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    addScriptToHead(null, scriptUrl)

    gtag('js', new Date());
    gtag('config', analyticsId);
}

export function initializeGoogleAnalyticsEvent(eventArgs) {
    gtag('event', 'conversion', eventArgs);
}

export function initializeGoogleTagManagerBody(iFrameSrc) {
    let innerHtml = `<iframe src=${iFrameSrc} height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    addNoScript(innerHtml);
}

export function initializeGoogleTagManagerHead(tagManagerId) {
    var scriptInner = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',";
    scriptInner += `'${tagManagerId}');`;

    addScriptToHead(scriptInner, null)
}


function addNoScript(innerHtml) {
    let noScript = document.createElement("noscript");
    noScript.innerHTML = innerHtml;
    document.body.appendChild(noScript);
}

function addScriptToHead(scriptHtml, scriptSrc) {
    if (!scriptHtml && !scriptSrc) {
        console.error("error in addScriptToHead, must have scripts inner html or a src");
        return;
    }

    let script = document.createElement("script");
    script.type = 'text/javascript';

    if (scriptHtml && !scriptExistsInHead(null, scriptHtml)) {
        script.innerHTML = scriptHtml;
    }
    else if (scriptSrc && !scriptExistsInHead(scriptSrc, null)) {
        script.src = scriptSrc;
    }

    document.head.appendChild(script);
}


function scriptExistsInHead(inScriptSrc, inScriptInnerHtml) {
    const allScripts = document.getElementsByTagName("script");
    for (let i = 0; i < allScripts?.length; i++) {
        if (inScriptSrc && allScripts[i].src === inScriptSrc) {
            return true;
        }
        else if (inScriptInnerHtml && allScripts[i].innerHTML === inScriptInnerHtml) {
            return true;
        }
    }
    return false;
}

/** * Google analytics function */
export function gtag(args) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
}

