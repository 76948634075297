import React, { useState } from 'react';

//!!Copied from pulse2
//!!Keep in sync!

/*
Height and width have to follow the Tailwinds values
https://tailwindcss.com/docs/width
https://tailwindcss.com/docs/height
*/

const Checkbox = ({
    backgroundClass = 'bg-gray-200',
    className = '',
    checked,
    disabled,
    height = 'h-6',
    initialValue = false,
    label,
    name,
    onChange,
    register,
    rules,
    watch,
    width = 'w-6'
}) => {
    const formValue = watch && watch(name);
    const [value, setValue] = useState(formValue ?? initialValue);

    const onChangeHandler = event => {
        if (disabled) return;
        if (checked === undefined) setValue(!value);

        onChange && onChange(event);
    };

    const isChecked = checked ?? value;
    return (
        <label
            className={`flex flex-shrink-0 gap-4 relative transition-all ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            } ${className}`}
        >
            <input
                name={name}
                {...(register ? register(name, rules) : {})}
                checked={isChecked}
                className={`${height} ${width} appearance-none ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    } transition-all rounded-md ${backgroundClass} border border-gray-200 hover:border-gray-silver checked:bg-blue-royal checkbox:border-blue-royal`}
                onChange={e => onChangeHandler(e)}
                disabled={disabled}
                type="checkbox"
            />
            {isChecked && (
                <div className={`${height} ${width} absolute flex justify-center items-center`}>
                    <TickSVG />
                </div>
            )}
            {label ? <span>{label}</span> : null}
        </label>
    );
};

const TickSVG = () => {
    return (
        <svg
            className="w-3/5 h-3/5 pointer-events-none transition-all"
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-9 -11)" fill="#FFFFFF" fillRule="nonzero">
                    <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                </g>
            </g>
        </svg>
    );
};

export default Checkbox;
