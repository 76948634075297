import React from 'react';

//!!Copied from pulse2
//!!Keep in sync!

export function Input({
    className,
    error,
    dataError = false,
    height = '',
    name,
    padding = '',
    register,
    rounded = '',
    rules,
    type = 'text',
    value
    //...rest
}) {
    return (
        <>
            <input
                name={name}
                {...(register ? register(name, rules) : {})}
                autoComplete="false"
                className={`${height} text-sm flex-grow transition-all outline-none block ${padding}
                      input-border active:ring ${rounded}  ${className}`}
                type={type}
                // {...rest}
                data-error={dataError}
                data-test={`input-${type}-${name}`}
                value={value}
            />
            {error && <span className="pl-2 text-red-500 text-xs">{error}</span>}
        </>
    );
}
