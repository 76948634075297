export function replaceLinksWithComponent(jsonDetails, linkClassNames = 'cursor-pointer') {
    if (!jsonDetails.links) {
        return jsonDetails.text;
    }
    else {
        let text = jsonDetails.text;
        jsonDetails.links.forEach(x => {
            const formattedLink = ` <a class='${linkClassNames}' href=${x.url} rel='noreferrer' target="_blank">${x.linkText}</a> `;
            text = text.replace(x.linkText, formattedLink);
        });
        return <span dangerouslySetInnerHTML={{ __html: text }} />
    }
}