import React, { useEffect, useRef } from 'react';
import NavItem from './NavItem';
import { useJsonData } from '../../hooks';

function NavItems() {
    const { navItems, showNavItems } = useJsonData();
    if (!showNavItems || !navItems || !navItems.length) return <></>;
    return (
        <div
            className="items-center w-full h-full hidden md:flex"
            style={{ fontSize: '12px', textTransform: 'uppercase', lineHeight: '18px', fontWeight: 'bold' }}
        >
            {navItems.map((n, i) => (
                <NavItem key={i} name={n.text} href={n.hyperlink} target="_none" />
            ))}
        </div>
    );
}

const Header = ({ headerContent }) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.innerHTML = headerContent;
    }, [ref, headerContent]);

    if (!headerContent) return <></>;

    return <div ref={ref} />;
};

export default function NavBar() {
    const {
        brand,
        logoSize,
        headerImage,
        headerContent,
        showF2M,
        showHeaderImage,
        centerLogo,
        leftAlignNavbar,
        bannerImageSizeXl,
        bannerImageSizeL
    } = useJsonData();


    const bannerStyles = {
        backgroundImage: `url(${`images/banners/${brand}-banner.jpg`})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };

    return (
        <div className={`w-full border-b border-white-grey bg-${brand || 'red'}`}>
            <div className={`max-w-screen-lg ${leftAlignNavbar ? 'pl-8' : 'm-auto'} px-5`}>
                <div
                    className={`flex items-center ${centerLogo ? 'justify-center' : ''} gap-x-5`}
                    style={{ height: '88px' }}
                >
                    {brand && (
                        <img
                            alt="logo"
                            style={{ height: logoSize?.height || '60px', width: logoSize?.width || 'auto' }}
                            src={`/images/${brand}Logo.png`}
                        />
                    )}
                    {brand && showF2M && <img alt="logo2" style={{ height: '60px' }} src={`/images/F2MlLogo.png`} />}
                    <NavItems />
                </div>
            </div>
            {showHeaderImage && (
                <div
                    style={bannerStyles}
                    className={`w-full ${bannerImageSizeXl ? 'h-120' : bannerImageSizeL ? 'h-100' : 'h-60'} bg-${brand || 'red'
                        } bg-${brand}-banner ${headerImage?.class || ''}`}
                >
                    {headerContent ? (
                        <Header headerContent={headerContent} />
                    ) : (
                        <div className="relative max-w-screen-lg px-5 m-auto h-full">
                            <div className="flex flex-row h-full items-center">
                                <p className={`text-${brand} text-2xl`} style={{ textShadow: 'black 0px 0px 5px' }}>
                                    Contact Us
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
