import React from "react";
import Button from "../button";

export function TextAwardTile({ brand, details }) {
    const lowerBrand = brand?.toLowerCase();

    const scrollTo = () => {
        if (details.buttonScrollTo) {
            window.location.hash = '';
            window.location.hash = `#${details.buttonScrollTo}`;
        }
    }

    return (
        <div className="text-center mx-6">
            <h1 className="text-2xl font-bold my-8">{details.title}</h1>
            <div className="w-1/2 mx-auto">
                <p>{details.paragraph}</p>
                <Button
                    className={`${lowerBrand ? 'bg-blue-' + lowerBrand + '-dark' : 'bg-blue-dark'
                        } text-white hover:${lowerBrand ? 'bg-blue-' + lowerBrand + '-light' : 'bg-blue-light'
                        } px-4 py-3 justify-center my-12 mx-auto`}
                    onClick={scrollTo}
                >
                    {details.buttonText}
                </Button>
            </div>
        </div>
    )
}