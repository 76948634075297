import React, { useRef, useEffect } from 'react';

export const PrivacyStatement = ({ privacyStatement }) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.innerHTML = privacyStatement;
    }, [ref, privacyStatement]);

    if (!privacyStatement) return <></>;

    return (
        <div className="text-center items-center content-center">
            <div style={{ width: '80%', maxWidth: '1280px', margin: 'auto' }} className="mb-4 text-center" ref={ref} />
        </div>
    );
};
