import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Listbox, Transition } from '@headlessui/react';
import IconComponent from '../icon';

// https://react-select.com/

function Option({ active, name, watch, option }) {
    const value = watch(name);

    const selected = option.id === value?.id;
    return (
        <Listbox.Option
            key={option.id}
            className={({ active }) =>
                `${
                    selected
                        ? 'text-blue-royal bg-blue-lightest'
                        : active
                        ? 'text-blue-royal bg-blue-lightest'
                        : 'text-gray-900'
                } cursor-default select-none relative py-2 pl-10 pr-4`
            }
            value={option}
        >
            {({ active, disabled }) => (
                <>
                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>{option.name}</span>
                    {selected ? (
                        <span
                            className={`${
                                active ? 'text-red-standard' : 'text-amber-600'
                            } absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                            <IconComponent icon="tick" colour="text-gray-400" height="8" />
                        </span>
                    ) : null}
                </>
            )}
        </Listbox.Option>
    );
}

export function HooksSelect({
    control,
    dataError,
    defaultValue,
    errors,
    name,
    onChange,
    options,
    placeholder = '',
    rules = {},
    watch
}) {
    return (
        <div>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field }) => {
                    const { ref, ...props } = field;
                    return (
                        <div className="w-full">
                            <Listbox
                                {...props}
                                onChange={(event) => {
                                    field.onChange(event);
                                    onChange && onChange(event);
                                }}
                            >
                                <div className="relative">
                                    <Listbox.Button
                                        className={() => `
                                    relative 
                                    w-full py-2 pl-3 pr-10 
                                    text-left 
                                    bg-white 
                                    cursor-default 
                                    input-border
                                    sm:text-sm `}
                                        data-error={!!errors?.[name] || dataError}
                                        data-test={`input-choice-${name}`}
                                    >
                                        {({ open }) => (
                                            <>
                                                <span className="block truncate">
                                                    <SelectedValue
                                                        placeholder={placeholder}
                                                        name={name}
                                                        watch={watch}
                                                    />
                                                </span>
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <IconComponent
                                                        className={`transition-transform ${open ? 'rotate-180 ' : ''}`}
                                                        icon="chevron"
                                                        colour="text-gray-400"
                                                        height="6"
                                                    />
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {options.map((option) => (
                                                <Option key={option.id} name={name} watch={watch} option={option} />
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                            {errors?.ref?.name || errors?.[name] ? (
                                <div className="text-red-standard text-xs pt-1 pl-2">
                                    {errors.message || errors?.[name]?.message}
                                </div>
                            ) : null}
                        </div>
                    );
                }}
            />
        </div>
    );
}

function SelectedValue({ name, watch, placeholder }) {
    const value = watch(name);
    return value?.name || <span className="text-gray-900 italic font-light">{placeholder}</span>;
}
