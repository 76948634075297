import React from 'react';
import { useJsonData } from '../../hooks';
import NavBar from '../navbar';

export default function AuthenticatedLayout({ children }) {
    const { brand } = useJsonData();
    return (
        <div className={`font-${brand}`}>
            {brand && (
                <nav>
                    <NavBar />
                </nav>
            )}
            <main>{children}</main>
        </div>
    );
}