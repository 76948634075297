import React from 'react';
import IconComponent from '../icon';

// Classes for styling taken from ../styles/globals.css

//size = default or small
const Button = ({
    children,
    className = '',
    dataTestId,
    disabled,
    icon,
    nextState,
    onClick,
    primary,
    size = 'default',
    submission,
    alternative,
    type = 'button'
}) => {
    const buttonType = primary
        ? size === 'default' || size === 'form'
            ? 'btn-primary'
            : 'btn-primary-small'
        : submission
        ? 'btn-complete-submission'
        : nextState
        ? 'btn-next-state'
        : alternative
        ? 'btn-alternative'
        : 'btn-secondary';

    //group added to button as class so the secondary button hover will have a white icon
    return (
        <div>
            <button
                className={`${size === 'default' ? 'px-4 py-3' : size === 'form' ? 'h-10' : 'px-2 py-1'} ${
                    icon ? 'btn-icon' : 'btn'
                } ${size === 'default' ? 'gap-4' : 'gap-2'}
                group ${buttonType} ${className} 
                disabled:opacity-50 disabled:bg-white disabled:cursor-default flex items-center`}
                data-test-id={dataTestId}
                disabled={disabled}
                onClick={event => onClick && onClick(event)}
                type={type}
            >
                {icon ? (
                    <IconComponent
                        icon={icon}
                        width={size === 'small' && 12}
                        className={
                            buttonType === 'btn-secondary' ? 'text-blue-light group-hover:text-white' : 'text-white'
                        }
                    />
                ) : null}
                {children}
            </button>
        </div>
    );
};

export function InlineButton({ icon, text }) {
    return (
        <button className="flex items-center gap-2 ali text-blue-royal focus:text-purple-standard hover:text-purple-standard">
            {icon ? <IconComponent icon={icon} width={12} className="" /> : null}
            <span className="underline">{text}</span>
        </button>
    );
}

export default Button;
