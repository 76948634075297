import React from 'react';
import { emailValidator, phoneValidator } from '../../services';
import { Input, CheckboxContainerStyled, HooksSelect } from '../';
import Checkbox from '../checkbox';

export function getInputValidator(type) {
    switch (type) {
        case 'email':
            return emailValidator;

        case 'tel':
            return phoneValidator;

        default:
            return;
    }
}

export function RenderInput(props) {
    const { rules, type } = props;
    const inputValidator = getInputValidator(type);

    switch (type) {
        case 'text':
        case 'email':
        case 'tel':
        case 'hidden':
            return (
                <Input
                    className="w-full"
                    {...props}
                    rules={{
                        validate: (value) =>
                            inputValidator ? inputValidator(value) || 'Phone number should be 11 characters' : true,
                        ...(rules ?? {})
                    }}
                />
            );

        case 'checkbox':
            return (
                <CheckboxContainerStyled>
                    <Checkbox {...props} />
                </CheckboxContainerStyled>
            );

        case 'select':
            return <HooksSelect {...props} />;

        default:
            return <div>Unknown type</div>;
    }
}
