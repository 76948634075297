import { useParams } from 'react-router-dom';
import sets from '../json';

/*
Links can be in the form of base64 encoded objects where the object contains key 'brand' and a corresponding client name value,
base64 encoded string where the string is the value of the client name, the client name (as it appears in the json set) itself,
or an integer corresponding to the property number of the client name from the sets shown above.

Peugeot
==========================================================================================================
Type                | String                                        | Evaluates to
----------------------------------------------------------------------------------------------------------
Base64 Object       | eyJicmFuZCI6IlBldWdlb3QifQ                    | { "brand": "Peugeot" }
Base64 String       | UGV1Z2VvdA==                                  | Peugeot
Basic String        | Peugeot                                       | Peugeot
Object key index    | 1                                             | 1

Citroën
==========================================================================================================
Type                | String                                        | Evaluates to
----------------------------------------------------------------------------------------------------------
Base64 Object       | eyJicmFuZCI6IkNpdHJvZW4ifQ==                  | { "brand": "Citroen" }
Base64 String       | Q2l0cm9lbg==                                  | Citroen
Basic String        | Citroen                                       | Citroen
Object key index    | 1                                             | 2

HyundaiBusiness
==========================================================================================================
Type                | String                                        | Evaluates to
----------------------------------------------------------------------------------------------------------
Base64 Object       | eyAiYnJhbmQiOiAiSHl1bmRhaUJ1c2luZXNzIiB9      | { "brand": "HyundaiBusiness" }
Base64 String       | SHl1bmRhaUJ1c2luZXNz                          | HyundaiBusiness
Basic String        | HyundaiBusiness                               | HyundaiBusiness
Object key index    | 3                                             | 3

*/

export function useJsonData() {
    const { d } = useParams();
    let set = null;
    if (!isNaN(d)) {
        const setKey = Object.keys(sets);
        set = sets[setKey[d]];
    }
    if (set) return set;
    try {
        set = sets[d];
    } catch (e) {


    }
    if (set) return set;
    try {
        const unbase64 = Buffer.from(d, 'base64').toString();
        set = sets[unbase64];
        if (set) return set;
        const data = JSON.parse(unbase64);
        const brand = data?.brand || unbase64;
        set = sets[brand];
        return set;
    } catch (e) {
        return {};
    }

}