import React from 'react';
import { Button } from '../../components';
export { HooksSelect } from './HooksSelect';
export * from './RenderInput';

export const FormItem = ({ children }) => {
    return <div className="grid grid-cols-3 items-center">{children}</div>;
};

export const LabelStyled = ({ children }) => {
    return <div className="font-semibold text-right mr-2">{children}</div>;
};

export const LabelStyledLeft = ({ children }) => {
    return <div className="font-medium text-left">{children}</div>;
};

export const InputContainerStyled = ({ children }) => {
    return <div className="col-span-2">{children}</div>;
};

export const CheckboxContainerStyled = ({ children }) => {
    return <div className="flex gap-2 items-center">{children}</div>;
};

export const SaveButton = ({ containerClassNames, buttonClassNames, disabled }) => {
    return (
        <div className={`${containerClassNames ? containerClassNames : 'grid grid-cols-3'}`}>
            <div />
            <Button
                className={`w-full justify-center ${buttonClassNames ? buttonClassNames : ''}`}
                disabled={disabled}
                type="submit"
            >
                Submit
            </Button>
        </div>
    );
};
