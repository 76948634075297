import React from "react";
import { FormItem, LabelStyled, LabelStyledLeft, RenderInput, SaveButton } from ".";
import { Input } from "../input";
import axios from 'axios';
import { PrivacyStatement } from "../privacyStatement";
import { useJsonData } from "../../hooks";
import { useForm } from "react-hook-form";

export const textOrHtml = (data) => {
    if (!data.hasHtml) {
        return data;
    }
    const fragment = <span dangerouslySetInnerHTML={{ __html: data.text }} />;
    return fragment;
}

export const states = {
    default: '',
    saving: 'saving',
    saved: 'saved',
    error: 'error'
};

async function onSubmit({ clientItemId, questions }, setStatus, selectedVehicle) {
    setStatus(states.saving);
    const answers = questions.map(({ question, answer }, idx) => {
        return {
            ordinal: idx,
            question,
            answer: answer?.name ? answer.name : answer
        };
    });
    //for selected veicle, find the right question. And give it the answer that we have in state
    const selectedVehicleQuestionIdx = answers.findIndex((x) => x.question?.toLowerCase() === 'selected vehicle');
    if (selectedVehicleQuestionIdx !== -1 && selectedVehicle) {
        answers[selectedVehicleQuestionIdx].answer =
            selectedVehicle !== 'Any' ? selectedVehicle.modelName + ' ' + selectedVehicle.fuelType : 'Any';
    }

    const dataToSubmit = {
        clientItemId,
        description: '',
        answers
    };

    const requestConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    axios
        .post(process.env.REACT_APP_LOCAL_ENDPOINT, dataToSubmit, requestConfig)
        .then(() => setStatus(states.saved))
        .catch(() => setStatus(states.error));
}

export function BrandForm({ brand, status, setStatus, selectedVehicle, questions, intro, introSubHeader, introSubHeaderClassNames }) {
    const data = useJsonData();
    const {
        horizontalStyledForm,
        validationInfo,
        privacyStatement
    } = data;

    const { handleSubmit, formState, ...form } = useForm({ defaultValues: data });

    const lowerBrand = brand?.toLowerCase();

    return horizontalStyledForm ? (
        <div className="pb-20" >
            <div className="m-auto px-12 pt-10 sm:1-2/3 md:w-1/2">
                <div className="mb-8">
                    <h1 className={`mb-6 text-3xl font-bold ${intro.textClassName ? intro.textClassName : ''}`}>{textOrHtml(intro)}</h1>
                    {introSubHeader ? (
                        <p className={`text-lg ${introSubHeaderClassNames ? introSubHeaderClassNames : ''}`}><span dangerouslySetInnerHTML={{ __html: introSubHeader }} /></p>
                    ) : <></>}

                    <p className="text-lg">{validationInfo}</p>
                </div>

                <form onSubmit={handleSubmit((data) => onSubmit(data, setStatus, selectedVehicle))}>
                    <Input name="clientItemId" type="hidden" />
                    <div className="grid gap-5 mb-5 lg:grid-cols-1 gap-y-8 xl:grid-cols-3 ">
                        {/* First 3 questions will be in 3 columns (as per design) */}
                        {questions &&
                            questions.slice(0, 3).map((x) => (
                                <div key={x.id}>
                                    <Input name={`questions.${x.id}.question`} type="hidden" />
                                    <LabelStyledLeft>
                                        {x.question}
                                        <RenderInput
                                            dataError={!!formState.errors?.questions?.[x.id]?.answer}
                                            error={formState.errors?.questions?.[x.id]?.answer?.message}
                                            errors={formState.errors?.questions?.[x.id]?.answer}
                                            name={`questions.${x.id}.answer`}
                                            {...x}
                                            {...form}
                                        />
                                    </LabelStyledLeft>
                                </div>
                            ))}
                    </div>

                    <div className="grid gap-5 mb-5 lg:grid-cols-1 gap-y-8 xl:grid-cols-2">
                        {/* Every other question will be in 2 column grid */}
                        {questions &&
                            questions
                                .filter((x) => x.type !== 'hidden')
                                .slice(3, questions.length)
                                .map((x) => (
                                    <div key={x.id}>
                                        <Input name={`questions.${x.id}.question`} type="hidden" />
                                        <LabelStyledLeft>
                                            {x.question}
                                            <RenderInput
                                                dataError={!!formState.errors?.questions?.[x.id]?.answer}
                                                error={formState.errors?.questions?.[x.id]?.answer?.message}
                                                name={`questions.${x.id}.answer`}
                                                {...x}
                                                {...form}
                                            />
                                        </LabelStyledLeft>
                                    </div>
                                ))}
                    </div>

                    {privacyStatement && <PrivacyStatement privacyStatement={privacyStatement} />}
                    <div className="justify-left mt-6">
                        <SaveButton
                            buttonClassNames={`${lowerBrand ? 'bg-blue-' + lowerBrand + '-dark' : 'bg-blue-dark'
                                } text-white hover:${lowerBrand ? 'bg-blue-' + lowerBrand + '-light' : 'bg-blue-light'
                                }`}
                            containerClassNames="xs:w-2/6 md:w-1/6"
                            disabled={status === states.saving}
                        />
                    </div>
                </form>
            </div>
        </div>
    ) : (
        <div className="w-full">
            <div className="p-8 flex-grow">
                <form
                    className="flex flex-col gap-4"
                    onSubmit={handleSubmit((data) => onSubmit(data, setStatus, selectedVehicle))}
                >
                    <div className="mb-2 text-center">{textOrHtml(intro)}</div>
                    <Input name="clientItemId" type="hidden" />
                    {(questions || []).map((m, idx) => (
                        <div className="mb-4" key={idx}>
                            <Input name={`questions.${idx}.question`} type="hidden" />
                            <FormItem>
                                <LabelStyled>{m.question}</LabelStyled>
                                <RenderInput
                                    dataError={!!formState.errors?.questions?.[idx]?.answer}
                                    error={formState.errors?.questions?.[idx]?.answer?.message}
                                    name={`questions.${idx}.answer`}
                                    {...m}
                                    {...form}
                                />
                            </FormItem>
                        </div>
                    ))}
                    {privacyStatement && <PrivacyStatement privacyStatement={privacyStatement} />}
                    <SaveButton disabled={status === states.saving} />
                </form>
            </div>
        </div>
    )
}